/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { store } from '../store';

import LayoutStyles from './layout.module.css';
import EkkoLogo from '../images/ekko-logo.jpg';

import { Layout, Menu, Drawer } from 'antd';
import {
  HomeOutlined,
  AppstoreAddOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  LoginOutlined,
  LogoutOutlined,
  FormOutlined,
} from '@ant-design/icons';

import { isLoggedIn, logout } from '../services/auth';

const { Footer, Content, Sider } = Layout;

const LayoutComponent = ({ children }) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [collapsed, setCollapsed] = useState(true);
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
      <Drawer
        title="EKKO Advance"
        placement="right"
        bodyStyle={{ padding: 0 }}
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        className={LayoutStyles.drawer}
      >
        <Menu
          className={LayoutStyles.menu}
          theme="light"
          selectedKeys={state.menuKey}
          mode="inline"
          onClick={({ key }) =>
            dispatch({ type: 'UPDATE_MENU_KEY', payload: [`${key}`] })
          }
        >
          {isLoggedIn() && (
            <Menu.Item
              key="1"
              disabled={true}
              style={{
                whiteSpace: 'normal',
                height: 'auto',
                fontSize: '1rem',
                cursor: 'initial',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              <div>{JSON.parse(localStorage.getItem('advanceUser')).email}</div>
              <div>
                {JSON.parse(localStorage.getItem('advanceUser')).course}
              </div>
            </Menu.Item>
          )}
          <Menu.Item key="2" icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<AppstoreAddOutlined />}>
            <Link to="/signup/">Sign Up</Link>
          </Menu.Item>
          {isLoggedIn() && (
            <Menu.Item key="4" icon={<FormOutlined />}>
              <Link to="/portal/content/">Content</Link>
            </Menu.Item>
          )}
          {isLoggedIn() ? (
            <Menu.Item key="5" icon={<LogoutOutlined />}>
              <Link to="/portal/login/" onClick={() => logout()}>
                Logout
              </Link>
            </Menu.Item>
          ) : (
            <Menu.Item key="6" icon={<LoginOutlined />}>
              <Link to="/portal/login/">Login</Link>
            </Menu.Item>
          )}
        </Menu>
      </Drawer>
      <Sider
        className={LayoutStyles.sider}
        theme="light"
        collapsible
        collapsed={collapsed}
        trigger={null}
      >
        <div className={LayoutStyles.logo}>
          <Link to="/">
            <img
              src={EkkoLogo}
              alt="Ekko Logo"
              style={{ width: '70px', marginBottom: '10px' }}
            />
          </Link>
        </div>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: LayoutStyles.trigger,
            onClick: () => setCollapsed(!collapsed),
          }
        )}
        <Menu
          className={LayoutStyles.menu}
          theme="light"
          selectedKeys={state.menuKey}
          mode="inline"
          onClick={({ key }) =>
            dispatch({ type: 'UPDATE_MENU_KEY', payload: [`${key}`] })
          }
        >
          {isLoggedIn() && (
            <Menu.Item
              key="11"
              disabled={true}
              style={{
                whiteSpace: 'normal',
                height: 'auto',
                fontSize: '1rem',
                cursor: 'initial',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {JSON.parse(localStorage.getItem('advanceUser')).email}
            </Menu.Item>
          )}
          <Menu.Item key="12" icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="13" icon={<AppstoreAddOutlined />}>
            <Link to="/signup/">Sign Up</Link>
          </Menu.Item>
          {isLoggedIn() && (
            <Menu.Item key="14" icon={<FormOutlined />}>
              <Link to="/portal/content/">Content</Link>
            </Menu.Item>
          )}
          {isLoggedIn() ? (
            <Menu.Item key="15" icon={<LogoutOutlined />}>
              <Link to="/portal/login/" onClick={() => logout()}>
                Logout
              </Link>
            </Menu.Item>
          ) : (
            <Menu.Item key="16" icon={<LoginOutlined />}>
              <Link to="/portal/login/">Login</Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <Layout className={LayoutStyles.content_layout}>
        <Content className={LayoutStyles.page_content}>
          <div className={LayoutStyles.page_content_background}>
            <button
              className={LayoutStyles.trigger_drawer}
              onClick={() => setDrawerVisible(!drawerVisible)}
            >
              <MenuOutlined />
            </button>
            <main>{children}</main>
          </div>
        </Content>
        <Footer className={LayoutStyles.footer}>Ekko Church © 2021</Footer>
      </Layout>
    </Layout>
  );
};

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutComponent;
