export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('advanceUser')
    ? JSON.parse(window.localStorage.getItem('advanceUser'))
    : {};

const setUser = (user) =>
  window.localStorage.setItem('advanceUser', JSON.stringify(user));

export const handleLogin = (email, password) => {
  const upperCaseEmail = email.toUpperCase();

  // const selahParticipants = process.env.SELAH_LIST.split(',');
  // const parentingParticipants = process.env.PARENTING_LIST.split(',');
  // const logosParticipants = process.env.LOGOS_LIST.split(',');
  // const doulosParticipants = process.env.DOULOS_LIST.split(',');
  const vocationParticipants = process.env.VOCATION_LIST.split(',');
  // const pneumaParticipants = process.env.PNEUMA_LIST.split(',');
  // const workParticipants = process.env.WORK_LIST.split(',');
  // const marriageParticipants = process.env.MARRIAGE_LIST.split(',');

  // if (
  //   selahParticipants.includes(upperCaseEmail) &&
  //   password === process.env.SELAH_PASSWORD
  // ) {
  //   return setUser({
  //     email,
  //     course: 'Selah',
  //   });
  // }
  // if (
  //   parentingParticipants.includes(upperCaseEmail) &&
  //   password === process.env.PARENTING_PASSWORD
  // ) {
  //   return setUser({
  //     email,
  //     course: 'Parenting',
  //   });
  // }

  // if (
  //   logosParticipants.includes(upperCaseEmail) &&
  //   password === process.env.LOGOS_PASSWORD
  // ) {
  //   return setUser({
  //     email,
  //     course: 'Logos',
  //   });
  // }
  // if (
  //   doulosParticipants.includes(upperCaseEmail) &&
  //   password === process.env.DOULOS_PASSWORD
  // ) {
  //   return setUser({
  //     email,
  //     course: 'Doulos',
  //   });
  // }
  if (
    vocationParticipants.includes(upperCaseEmail) &&
    password === process.env.VOCATION_PASSWORD
  ) {
    return setUser({
      email,
      course: 'Vocation',
    });
  }
  // if (
  //   pneumaParticipants.includes(upperCaseEmail) &&
  //   password === process.env.PNEUMA_PASSWORD
  // ) {
  //   return setUser({
  //     email,
  //     course: 'Pneuma',
  //   });
  // }
  // if (
  //   workParticipants.includes(upperCaseEmail) &&
  //   password === process.env.WORK_PASSWORD
  // ) {
  //   return setUser({
  //     email,
  //     course: 'Work',
  //   });
  // }
  // if (
  //   marriageParticipants.includes(upperCaseEmail) &&
  //   password === process.env.MARRIAGE_PASSWORD
  // ) {
  //   return setUser({
  //     email,
  //     course: 'Marriage',
  //   });
  // }

  return false;
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user.email;
};

export const logout = (callback) => {
  window.localStorage.clear();
  // callback();
};
